import { useState, useEffect } from "react";

type ThemeType = "light" | "dark";

const useTheme = () => {
  const initTheme = localStorage.getItem("theme") as ThemeType;
  const [theme, setTheme] = useState<ThemeType>(initTheme || "light");

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    let newTheme: ThemeType = theme === "light" ? "dark" : "light";
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
  };

  return { theme, toggleTheme };
};

export { useTheme };
