import Main from "./pages/Main";

import Header from "./layouts/Header";
import Footer from "./layouts/Footer";

import { UserProvider } from "./context/userContext";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

import Result from "./pages/Result/Result";
import Settings from "./pages/Settings/Settings";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [isLite, setIsLite] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    axios.get('/settings').then(({ data }: { data: { questionMode: string } }) => {
      if (data.questionMode == 'lite') {
        setIsLite(true);
      } 
      setIsLoading(false);
    });
  }, []);



  const { href, host, pathname, search, hash } = window.location;

  let view = <Main />;

  if ("/result" == pathname) {
    view = <Result />;
  } else if (pathname == "/settings") {
    view = <Settings />;
  }

  if (isLoading) return null;

  return (
    <div data-theme={isLite ? 'orange' : 'red'} className="min-h-screen flex flex-col justify-between">
      <Header isLite={isLite} />

      <UserProvider>{view}</UserProvider>
      {/* <Footer /> */}
      <ToastContainer />
    </div>
  );
}

// function ResultPage() {
//   const [list, setList] = useState([]);
//   useEffect(() => {
//     axios.get("/test-result").then((res) => {
//       setList(res.data);
//     });
//   }, []);

//   return (
//     <div className="p-6">
//       <div className="px-4 mb-5">
//         {list.map((l: any) => {
//           return (
//             <div className="card mb-4 bg-base-300  shadow-xl overflow-hidden p-4">
//               <div className="grid grid-cols-2 gap-2">
//                 <div>
//                   <div>
//                     <p>{l?.domain}</p>
//                     <p>{l?.user?.email}</p>
//                     <p>{l?.user?.name}</p>
//                     <p>{l?.user?.city}</p>
//                     <p>{l?.user?.country}</p>
//                     <p>{l?.user?.phone}</p>
//                     <p>{moment(l?.createdAt).format("HH:mm DD.MM.YYYY")}</p>
//                   </div>
//                   <div className="divider" />

//                   <div>
//                     <div className="mt-5">
//                       {l.traits.map((p: any) => {
//                         return (
//                           <div className="text-xs">
//                             {p.trait.rus} {p.value}
//                           </div>
//                         );
//                       })}
//                     </div>
//                   </div>
//                 </div>

//                 <div>
//                   <div>
//                     {l.professions.map((p: any) => {
//                       return (
//                         <div className="text-xs mb-1">
//                           {p.profession.rus} - Checksum: {p.checksum}, Percent:
//                           {p.percent}%
//                         </div>
//                       );
//                     })}
//                   </div>
//                   <div className="divider" />
//                   <div className="mt-5">
//                     {l.characters.map((p: any) => {
//                       return (
//                         <div className="text-xs mb-1">
//                           {p.character.character}({p.character.movie}) -
//                           Checksum: {p.checksum}, Percent:
//                           {p.percent}%
//                         </div>
//                       );
//                     })}
//                   </div>
//                 </div>
//               </div>
//               {/* <div className="p-4">
//                 <div className="flex">

//                 </div>

//                 <div className="divider"></div>
//                 <div className="flex">
//                   <div>
//                     {l.traits.map((p: any) => {
//                       return (
//                         <div>
//                           {p.trait.positive.rusName} {p.value}
//                         </div>
//                       );
//                     })}
//                   </div>
//                 </div>
//               </div> */}
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }

export default App;
