import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import rusTranslations from "./locales/rus.json";
import geoTranslations from "./locales/geo.json";
import engTranslations from "./locales/eng.json";

const domain = window.document.domain;

let initLang = "rus";

const isDevMode = process.env.NODE_ENV === "development";

// if (!isDevMode) {
//   if (domain == "thejobgate.com") {
//     initLang = "geo";
//   } else if (domain == "jbhnd.com") {
//     initLang = "rus";
//   }
// }

i18n.use(initReactI18next).init({
  resources: {
    geo: {
      translation: geoTranslations,
    },
    rus: {
      translation: rusTranslations,
    },
    eng: {
      translation: engTranslations,
    },
  },
  lng: initLang, // язык по умолчанию
  fallbackLng: "rus", // язык по умолчанию если перевод не найден
  interpolation: {
    escapeValue: false, // для React не нужно экранировать значения
  },
});

export default i18n;
