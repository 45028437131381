import React, { useEffect, useState } from "react";

import axios from "axios";

import moment from "moment";
import { Icon } from "@iconify/react";

const Result: React.FC = () => {
  const [list, setList] = useState([]);
  const [version, setVersion] = useState<string>("default");
  useEffect(() => {
    axios
      .get(
        "/test-result",
        {
          params: {
            version,
          },
        }
      )
      .then((res) => {
        setList(res.data);
      });
  }, [version]);

  const deleteItemHandler = (id: string) => {
    if (!window.confirm("Удалить тест?")) return;
    axios.delete("/test-result", { data: { id } }).then(() => {
      setList((list) => list.filter((i: any) => i?._id !== id));
    });
  };

  return (
    <div className="p-6">
      <div className="px-4 mb-5">
        <div role="tablist" className="tabs tabs-boxed mb-4">
          <a
            role="tab"
            onClick={() => setVersion("default")}
            className={version == "default" ? "tab tab-active" : "tab"}
          >
            Обычная
          </a>
          <a
            role="tab"
            onClick={() => setVersion("2")}
            className={version == "2" ? "tab tab-active" : "tab"}
          >
            Версия 2
          </a>
          <a
            role="tab"
            onClick={() => setVersion("lite")}
            className={version == "lite" ? "tab tab-active" : "tab"}
          >
            Lite
          </a>

        </div>
        {version == "default" &&
          list.map((it) => (
            <ItemCard deleteItemHandler={deleteItemHandler} data={it} />
          ))}
        {version == "2" &&
          list.map((it) => (
            <ItemCard2 deleteItemHandler={deleteItemHandler} data={it} />
          ))}
        {version == "lite" &&
          list.map((it) => (
            <ItemCard2 deleteItemHandler={deleteItemHandler} data={it} />
          ))}
      </div>
    </div>
  );
};

const FieldItem = ({ name = "", value = "" }) => {
  return (
    <div>
      <p className="text-secondary-content text-sm">{name}</p>
      <p className="text-accent text-lg font-bold">{value}</p>
    </div>
  );
};

const ItemCard = ({
  data,
  deleteItemHandler,
}: {
  data: any;
  deleteItemHandler: Function;
}) => {
  return (
    <div className="card mb-4 bg-base-100  shadow-xl overflow-hidden p-4">
      <div className=" mx-2 flex justify-end">
        <button
          onClick={() => deleteItemHandler(data._id)}
          className="btn btn-square btn-outline btn-error"
        >
          <Icon fontSize="26px" icon="mdi:trash-outline" />
        </button>
      </div>
      <div>
        <div className="px-4 py-2">
          <div className="grid sm:grid-cols-2 md:grid-cols-4">
            <FieldItem name="Статус" value={getStatusName(data.user.status)} />
            <FieldItem name="Домен" value={data?.domain} />
            <FieldItem name="Email" value={data?.user?.email} />
            <FieldItem name="Имя" value={data?.user?.name} />
            <FieldItem name="Город" value={data?.user?.city} />
            <FieldItem name="Страна" value={data?.user?.country} />
            <FieldItem name="Телефон" value={data?.user?.phone || "-"} />
            <FieldItem
              name="Дата создания"
              value={moment(data?.createdAt).format("HH:mm DD.MM.YYYY")}
            />
          </div>
        </div>
        <p className="text-primary px-4 mb-4">
          P-{data.PAEI.P.value}({data.PAEI.P.percent}%), A-{data.PAEI.A.value}(
          {data.PAEI.A.percent}%), E-{data.PAEI.E.value}({data.PAEI.E.percent}
          %),I -{data.PAEI.I.value}({data.PAEI.I.percent}%)
        </p>
      </div>
      <div className="collapse collapse-arrow bg-base-100 p-1 mt-2 bg-base-100">
        <input type="checkbox" />
        <div className="collapse-title text-lg font-medium">Черты</div>

        <div className="collapse-content">
          <div className="overflow-x-auto">
            <table className="table">
              <thead>
                <tr>
                  <th>Название</th>
                  <th>Позитив</th>
                  <th>Негатив</th>
                  <th>Результат</th>
                  <th>Позитив PAEI</th>
                  <th>Негатив PAEI</th>
                </tr>
              </thead>
              <tbody>
                {data.traits.map((t: any) => {
                  return (
                    <tr>
                      <td>{t.name}</td>
                      <td className="text-success">{t.positiveValue}</td>
                      <td className="text-error">{t.negativeValue}</td>

                      <td className="text-primary">{t.value}</td>
                      <td className="text-success">{t.positiveAdezisCode}</td>
                      <td className="text-error">{t.negativeAdezisCode}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="collapse collapse-arrow  p-1 mt-2 bg-base-100">
        <input type="checkbox" />
        <div className="collapse-title text-lg font-medium">Профессии</div>
        <div className="collapse-content">
          <div className="overflow-x-auto">
            <table className="table">
              {/* head */}
              <thead>
                <tr>
                  <th>Название</th>
                  <th>Чексумма</th>
                  <th>Процент</th>
                  <th>PAEI</th>
                </tr>
              </thead>
              <tbody>
                {data.professions.map((t: any) => {
                  return (
                    <tr>
                      <td>{t.name}</td>
                      <td>{t.checksum}</td>
                      <td className="text-success">{t.percent} %</td>
                      <td>{t.codes.join(" ")}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <div className="collapse p-1 mt-2 bg-base-100">
        <input type="checkbox" />
        <div className="collapse-title text-lg font-medium">Персонаж</div>
        <div className="collapse-content">
          <div className="overflow-x-auto">
            <table className="table">
              <thead>
                <tr>
                  <th>Персонаж</th>
                  <th>Фильм</th>
                  <th>Чексумма</th>
                  <th>Процент</th>
                </tr>
              </thead>
              <tbody>
                {data.characters.map((t: any) => {
                  return (
                    <tr>
                      <td className="text-primary">{t.character.character}</td>
                      <td>{t.character.movie}</td>
                      <td>{t.checksum}</td>
                      <td className="text-success">{t.percent} %</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
      <div className="collapse collapse-arrow  p-1 mt-2 bg-base-100">
        <input type="checkbox" />
        <div className="collapse-title text-lg font-medium">Блогер</div>
        <div className="collapse-content">
          <div className="overflow-x-auto">
            <table className="table">
              {/* head */}
              <thead>
                <tr>
                  <th>Платформа</th>
                  <th>Жанр</th>
                  <th>Чексумма</th>
                  <th>Процент</th>
                </tr>
              </thead>
              <tbody>
                {data.bloggers.map((t: any) => {
                  return (
                    <tr>
                      <td className="text-primary">{t.platform}</td>
                      <td>{t.genre}</td>
                      <td>{t.checksum}</td>
                      <td className="text-success">{t.percent} %</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

function isNumber(val: number) {
  return !isNaN(val);
}

const ItemCard2 = ({
  data,
  deleteItemHandler,
}: {
  data: any;
  deleteItemHandler: Function;
}) => {
  return (
    <div className="card mb-4 bg-base-100  shadow-xl overflow-hidden p-4">
      <div className=" mx-2 flex justify-end">
        <button
          onClick={() => deleteItemHandler(data._id)}
          className="btn btn-square btn-outline btn-error"
        >
          <Icon fontSize="26px" icon="mdi:trash-outline" />
        </button>
      </div>
      <div>
        <div className="px-4 py-2">
          <div className="grid sm:grid-cols-2 md:grid-cols-4">
            <FieldItem name="Пол" value={getSexName(data.user.sex)} />
            <FieldItem name="Статус" value={getStatusName(data.user.status)} />
            <FieldItem name="Домен" value={data?.domain} />
            <FieldItem name="Email" value={data?.user?.email} />
            <FieldItem name="Имя" value={data?.user?.name} />
            <FieldItem name="Город" value={data?.user?.city} />
            <FieldItem name="Страна" value={data?.user?.country} />
            <FieldItem name="Телефон" value={data?.user?.phone || "-"} />
            <FieldItem
              name="Дата создания"
              value={moment(data?.createdAt).format("HH:mm DD.MM.YYYY")}
            />
          </div>
        </div>
        <p className="text-primary px-4 mb-4">
          P-{data.PAEI.P.value}({data.PAEI.P.percent}%), A-{data.PAEI.A.value}(
          {data.PAEI.A.percent}%), E-{data.PAEI.E.value}({data.PAEI.E.percent}
          %),I -{data.PAEI.I.value}({data.PAEI.I.percent}%)
        </p>

        {data.isGenius && (
          <p className="text-accent text-lg font-bold">Гений</p>
        )}
      </div>
      <div className="collapse collapse-arrow bg-base-100 p-1 mt-2 bg-base-100">
        <input type="checkbox" />
        <div className="collapse-title text-lg font-medium">Черты</div>

        <div className="collapse-content">
          <div className="overflow-x-auto">
            <table className="table">
              <thead>
                <tr>
                  <th>Название</th>
                  <th>Коэфициент</th>
                  <th>Позитив</th>
                  <th>Негатив</th>
                  <th>Результат</th>
                  <th>Позитив PAEI</th>
                  <th>Негатив PAEI</th>
                </tr>
              </thead>
              <tbody>
                {data.traits.map((t: any) => {
                  let exist =
                    isNumber(t.positiveValue) && isNumber(t.negativeValue);
                  let isPositive = t.positiveValue >= t.negativeValue;

                  return (
                    <tr>
                      <td>{t.name}</td>
                      <td className="text-success">{t.coeff}</td>
                      <td className="text-success">{t.positiveValue}</td>
                      <td className="text-error">{t.negativeValue}</td>
                      {exist ? (
                        <td
                          className={isPositive ? "text-success" : "text-error"}
                        >
                          {isPositive ? t.value : -t.value}
                        </td>
                      ) : (
                        <td className="text-success">{t.value}</td>
                      )}

                      <td className="text-success">{t.positiveAdezisCode}</td>
                      <td className="text-error">{t.negativeAdezisCode}</td>
                    </tr>
                  );
                })}
              </tbody>
              {/* <thead>
                <tr>
                  <th>Название</th>
                  <th>Коэфициент</th>
                  <th>Результат</th>
                  <th>PAEI</th>
                </tr>
              </thead>
              <tbody>
                {data.traits.map((t: any) => {
                  return (
                    <tr>
                      <td>{t.name}</td>
                      <td className="text-success">{t.coeff}</td>
                      <td className="text-error">{t.value}</td>
                      <td className="text-error">{t.code}</td>
                    </tr>
                  );
                })}
              </tbody> */}
            </table>
          </div>
        </div>
      </div>
      <div className="collapse collapse-arrow  p-1 mt-2 bg-base-100">
        <input type="checkbox" />
        <div className="collapse-title text-lg font-medium">Профессии</div>
        <div className="collapse-content">
          <div className="overflow-x-auto">
            <table className="table">
              {/* head */}
              <thead>
                <tr>
                  <th>Название</th>

                  <th>PAEI</th>
                </tr>
              </thead>
              <tbody>
                {data.professions.map((t: any) => {
                  return (
                    <tr>
                      <td>{t.name}</td>
                      <td>{t.codes.join(" ")}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* <div className="collapse p-1 mt-2 bg-base-100">
        <input type="checkbox" />
        <div className="collapse-title text-lg font-medium">Персонаж</div>
        <div className="collapse-content">
          <div className="overflow-x-auto">
            <table className="table">
              <thead>
                <tr>
                  <th>Персонаж</th>
                  <th>Фильм</th>
                  <th>PAEI</th>
                </tr>
              </thead>
              <tbody>
                {data.characters.map((t: any) => {
                  return (
                    <tr>
                      <td className="text-primary">{t.character.character}</td>
                      <td>{t.character.movie}</td>
                      <td className="text-success">
                        {t.character.codes.join("")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
      {/* <div className="collapse collapse-arrow  p-1 mt-2 bg-base-100">
        <input type="checkbox" />
        <div className="collapse-title text-lg font-medium">Блогер</div>
        <div className="collapse-content">
          <div className="overflow-x-auto">
            <table className="table">
              <thead>
                <tr>
                  <th>Платформа</th>
                  <th>Жанр</th>
                  <th>Чексумма</th>
                  <th>Процент</th>
                </tr>
              </thead>
              <tbody>
                {data.bloggers.map((t: any) => {
                  return (
                    <tr>
                      <td className="text-primary">{t.blogger.platform}</td>
                      <td>{t.blogger.genre}</td>
                      <td>{t.checksum}</td>
                      <td className="text-success">{t.percent} %</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
    </div>
  );
};

function getSexName(s: string) {
  switch (s) {
    case "male":
      return "Мужчина";
    case "female":
      return "Женщина";
  }
}

function getStatusName(s: string) {
  switch (s) {
    case "student":
      return "Студент";
    case "search-work":
      return "Ищет работу";
    case "work":
      return "Работает,но ищет новую работу";
  }
}

export default Result;
