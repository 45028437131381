// import { Icon } from "@iconify/react";

import { Icon } from "@iconify/react";
import { useTheme } from "../hooks/useTheme";

import i18n from "i18next";
import { useEffect } from "react";

const domain = window.document.domain;
const isDevMode = process.env.NODE_ENV === "development";

export default function Header({isLite = false}) {
  let { toggleTheme, theme } = useTheme();

  function changeLanguage(e: any) {
    let value = e.target.value;
    i18n.changeLanguage(value);
  }

  let DOMAIN = "PROD";

  if (!isDevMode) {
    DOMAIN = domain.split(".")[0];
  }

  useEffect(() => {
    document.title = DOMAIN;
  }, []);

  // console.log(DOMAIN, 'DOMAIN')

  // useEffect

  // const DOMAIN = "thejobgat

  return (
    <div className=" pt-4 px-8  ">
      <div className="container items-center mx-auto flex justify-between ">
        <div>
          {isLite ? <p className="text-xl font-bold text-primary">v. Lite</p> : null}
        </div>
        <div className="flex-none">
          <select
            onChange={changeLanguage}
            className="select  select-bordered w-full max-w-xs"
          >
            <option value="rus">RUS</option>
            <option value="geo">GEO</option>
            <option value="eng">ENG</option>
          </select>
        </div>
      </div>
    </div>
  );
}

// user-auth
//
